@import url(https://fonts.googleapis.com/css2?family=Jura:wght@300&family=Marvel&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*  
Thanks to: Usama Tahir
https://medium.com/@AmJustSam
*/
*{
  margin: 0;
  padding: 0;
 }
 
 html{
     width: 100vw;
     height: 100vh;
 }
 
 /* CSS which you need for blurred box */
 body{
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: top;
  background-image:url(/static/media/background.e670af76.jpg);
  width: 100%;
  font-family: Arial, Helvetica;
  letter-spacing: 0.02em;
   font-weight: 400;
  -webkit-font-smoothing: antialiased; 
 }
 
 .blurred-box{
   position: relative;
   width: 250px;
   height: 350px;
   top: calc(275px);
   left: calc(50% - 125px);
   background: inherit;
   border-radius: 2px;
   overflow: hidden;
   -webkit-backdrop-filter: blur(10px);
           backdrop-filter: blur(10px);
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
 }
 
 .blurred-box:after{
  content: '';
  width: 300px;
  height: 300px;
  background: inherit; 
  position: absolute;
  left: -25px;
  right: 0;
  top: -25px;  
  bottom: 0;
  box-shadow: inset 0 0 0 200px rgba(255,255,255,0.05);
  -webkit-filter: blur(10px);
          filter: blur(10px);
 }
 
 
 /* Form which you dont need */
 .user-login-box{
   position: relative;
   margin-top: 50px;
   text-align: center;
   z-index: 1;
 }
 .user-login-box > *{
   display: inline-block;
   width: 200px;
 }
 
 .user-icon{
   width: 100px;
   height: 100px;
   position: relative;
   border-radius: 50%;
   background-size: contain;
   background-image: url(https://pbs.twimg.com/profile_images/725321660484583424/ArQ4fM3k.jpg);
 }
 
 .user-name{
   margin-top: 15px;
   margin-bottom: 15px;
   color: white;
 }
 
 input.user-password{
   width: 120px;
   height: 18px;
   opacity: 0.4;
   border-radius: 2px;
   padding: 5px 15px;
   border: 0;
 }
 
 
